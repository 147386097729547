const SerivcesData = [
    {
        id: 'safety-audits-and-compliance',
        title: 'Safety Audits and Compliance',
        image: '/images/services-01.png',
        subCategories: [
            {
                id: 1,
                title: 'Fire Safety Audit',
                heading: 'Why should you place your trust in us for conducting a thorough fire safety audit?',
                headingText: `Our team of seasoned professionals conducts a thorough evaluation, delivering a bespoke fire safety roadmap that not only meets regulatory standards but exceeds them, assuring unparalleled safety for your establishment. We\'ve assisted numerous businesses in fortifying their fire safety protocols. Our audit study is a catalyst for reducing risks and enhancing your facility\'s resilience against fire hazards.`,
                content: `<p>A <strong class="text-primary-900">Fire safety audit</strong> is a systematic evaluation of a building or facility to assess its level of compliance with fire safety regulations and standards. It involves a comprehensive review of various fire safety measures, equipment, and procedures in place to identify potential hazards and ensure the safety of occupants in the event of a fire. The primary goal of a fire safety audit is to minimize the risk of fire incidents, protect lives, and safeguard property.</p>

                <p>During a fire safety audit, a trained professional, such as a fire safety officer or a fire inspector, examines different aspects of fire safety within a building. Here are some key areas typically assessed during a fire safety audit:</p>

                <p><strong class="text-primary-900">1. Building Layout and Construction:</strong> The auditor evaluates the building's structure, layout, and design to ensure compliance with fire codes and standards. This includes assessing the availability and condition of fire exits, evacuation routes, stairwells, and other means of egress.</p>

                <p><strong class="text-primary-900">2. Fire Protection Systems:</strong> The audit covers the inspection and testing of fire protection systems, such as fire alarms, smoke detectors, fire sprinklers, fire extinguishers, emergency lighting, and fire hydrants. The auditor checks their installation, functionality, maintenance records, and compliance with relevant regulations.</p>

                <p><strong class="text-primary-900">3. Emergency Response Planning:</strong> The auditor reviews the facility's emergency response plan, which includes procedures for evacuation, fire drills, alarm systems, communication systems, and designated assembly areas. They assess the effectiveness of these plans and the level of staff training and awareness.</p>

                <p><strong class="text-primary-900">4. Fire Safety Training:</strong> The audit examines the training programs provided to employees or occupants, ensuring they receive proper fire safety education and know how to respond in case of an emergency. The auditor may request documentation and records of training sessions conducted.</p>

                <p><strong class="text-primary-900">5. Electrical Systems:</strong> The auditor inspects electrical panels, wiring, and equipment to identify potential fire hazards, such as overloaded circuits, exposed wires, or faulty installations. Compliance with electrical safety codes is assessed.</p>
                
                <p><strong class="text-primary-900">6. Hazardous Materials and Storage:</strong> If applicable, the auditor examines the storage and handling of flammable or hazardous materials within the facility. They verify compliance with regulations regarding storage, labelling, handling procedures, and the presence of safety data sheets.</p>

                <p><strong class="text-primary-900">7. Recordkeeping and Documentation:</strong> The auditor checks the maintenance records, inspection logs, and documentation related to fire safety measures. This includes fire alarm testing records, inspection certificates for fire extinguishers and sprinkler systems, and any required permits or licenses..</p>

                <p>Following the fire safety audit, the auditor typically prepares a report that outlines the findings, identifies areas of non-compliance or improvement, and provides recommendations for rectifying any identified issues. This report serves as a guideline for the building owner or manager to address fire safety concerns and ensure the facility meets the necessary fire safety standards and regulations. Regular fire safety audits are essential to maintaining a safe environment and minimizing the risk of fire-related incidents.</p>`,
                
                banners: [
                    {
                        image: '/images/servicesslider/fire-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/fire-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/fire-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 2,
                title: 'Electrical Safety Audit',
                heading: 'Why should you place your trust in us for conducting a thorough electrical safety audit?',
                headingText: `Our team of seasoned professionals conducts a thorough evaluation, delivering a bespoke electrical safety roadmap that not only meets regulatory standards but exceeds them, assuring unparalleled safety for your establishment.We\'ve assisted numerous businesses in fortifying their electrical safety protocols. Our audit study is a catalyst for reducing risks and enhancing your facility\'s resilience against electrical hazards`,
                content: `<p><strong class="text-primary-900">An electrical safety</strong> audit is a systematic examination of a workplace, facility, or residential area to assess the electrical systems, equipment, and procedures in place to ensure they comply with safety standards, regulations, and best practices. The primary goal of an electrical safety audit is to identify potential hazards, risks, or non-compliance issues related to electrical installations and operations and to recommend corrective measures to enhance safety.</p>

                <p>Key components of an electrical safety audit typically include:</p>

                <p><strong class="text-primary-900">1. Inspection of Electrical Systems:</strong> This involves a thorough examination of electrical panels, wiring, circuits, outlets, switches, equipment, and machinery to check for any signs of wear and tear, damage, or overloading.</p>

                <p><strong class="text-primary-900">2. Code Compliance Check:</strong> Ensuring that all electrical installations and systems meet the relevant local, national, and industry-specific electrical codes and standards.</p>

                <p><strong class="text-primary-900">3. Grounding and Bonding Evaluation:</strong> Verifying proper grounding and bonding of electrical systems to prevent electrical shocks and fires.</p>

                <p><strong class="text-primary-900">4. Safety Devices and Equipment Assessment:</strong> Inspecting the functionality and adequacy of safety devices such as circuit breakers, fuses, ground fault circuit interrupters (GFCIs), surge protectors, etc.</p>

                <p><strong class="text-primary-900">5. Documentation Review:</strong> Checking maintenance records, electrical drawings, permits, and other relevant documentation to ensure compliance and proper record-keeping.</p>
                
                <p><strong class="text-primary-900">6. Training and Procedures Review:</strong> Evaluating the training programs and safety procedures in place for personnel working with electrical equipment to ensure they are aware of potential hazards and know how to mitigate risks.</p>

                <p><strong class="text-primary-900">7. Risk Assessment:</strong> Identifying potential risks and hazards associated with electrical systems and providing recommendations to mitigate these risks.</p>

                <p><strong class="text-primary-900">8. Recommendations and Action Plan:</strong> Providing a detailed report with findings and recommendations for corrective actions to address any identified issues or areas of improvement.
                </p>

                <p>It's crucial to conduct electrical safety audits periodically to maintain a safe working or living environment and prevent accidents, electrical shocks, fires, and other potential hazards. Depending on the findings of the audit, necessary corrective actions should be implemented promptly to mitigate risks and ensure ongoing compliance with safety standards.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/electrical-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electrical-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electrical-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 3,
                title: 'Thermography',
                heading: 'Why should you place your trust in us for conducting thermography study?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Thermography</strong> also known as thermal imaging, is a valuable tool for inspecting electrical systems. It involves using an infrared camera to capture the temperature variations in an object or system, which can help identify potential issues in electrical components.</p>

                <p>In electrical systems, thermography is commonly used for preventive maintenance and troubleshooting. Here's how thermography is applied to electrical systems:</p>

                <p><strong class="text-primary-900">1. Identifying Overheating Components:</strong> Thermal imaging can detect hotspots in electrical components, such as circuits, connections, switches, and panels. Overheating may indicate loose connections, overloaded circuits, imbalanced loads, or faulty components.</p>

                <p><strong class="text-primary-900">2. Preventive Maintenance:</strong> Regular thermographic inspections can help identify problems before they cause failures or safety hazards. By detecting hotspots early, maintenance can be scheduled to address issues like loose connections, corrosion, or faulty equipment.</p>

                <p><strong class="text-primary-900">3. Safety Inspections:</strong> Thermography allows for non-contact inspection, which is particularly useful in high-voltage environments where physical contact might be dangerous. It helps identify potential hazards without the need to shut down equipment.</p>

                <p><strong class="text-primary-900">4. Efficiency Monitoring:</strong> Monitoring temperature variations can also reveal inefficiencies in electrical systems, such as imbalanced loads or equipment running hotter than usual, which might indicate the need for adjustments or upgrades.</p>

                <p>When conducting a thermographic study for electrical systems, certain best practices should be followed:</p>

                <p><strong class="text-primary-900">1. Thorough Inspection:</strong> Check all electrical components systematically, including connections, fuses, circuit breakers, motors, and distribution panels.</p>
                
                <p><strong class="text-primary-900">2. Proper Equipment:</strong> Use high-quality thermal imaging cameras calibrated for electrical inspections to ensure accurate readings.</p>

                <p><strong class="text-primary-900">3. Safety Measures:</strong> Ensure that proper safety protocols are followed, especially in high-voltage environments, and use appropriate personal protective equipment (PPE).</p>

                <p><strong class="text-primary-900">4. Recording and Analysis:</strong> Record thermal images and temperature measurements for analysis. Compare current readings to baseline data to detect any abnormal changes.
                </p>

                <p><strong class="text-primary-900">5. Qualified Personnel:</strong> Thermography should ideally be conducted by trained and certified thermographers who understand electrical systems and the interpretation of thermal images.
                </p>

                <p>Overall, thermography is an effective non-destructive testing method for detecting potential issues in electrical systems, allowing for proactive maintenance and preventing costly downtime or safety hazards.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/thermography-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/thermography-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/thermography-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 4,
                title: 'Liability Risk Assessment',
                heading: 'Why should you place your trust in us for conducting Liability Risk Assessment ? ',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance.`,
                content: `<p><strong class="text-primary-900">A liability assessment</strong> study for a factory or insured entity typically involves a comprehensive analysis of potential risks and liabilities associated with the operation of the factory or business. Here are the general steps involved in conducting such a study.</p>

                <p><strong class="text-primary-900">1. Identify Potential Risks:</strong> Review all aspects of the factory's operations, including manufacturing processes, equipment, employee activities, and any potential hazards. Identify potential risks that could lead to property damage, injury to employees or third parties, environmental damage, or financial loss.</p>

                <p><strong class="text-primary-900">2. Regulatory Compliance:</strong> Ensure compliance with local, state, and federal regulations pertaining to the industry, workplace safety, environmental standards, etc. Non-compliance could lead to legal liabilities.</p>

                <p><strong class="text-primary-900">3. Site Assessment:</strong> Conduct a physical assessment of the factory site to identify any safety hazards, structural weaknesses, or environmental risks.</p>

                <p><strong class="text-primary-900">4. Review Insurance Coverage:</strong> Assess the adequacy of current insurance coverage. Ensure that the factory/insured entity has appropriate liability insurance in place to mitigate potential risks.</p>

                <p><strong class="text-primary-900">5. Employee Training and Safety Protocols:</strong> Evaluate the effectiveness of existing safety protocols and employee training programs. Ensuring that employees are well-trained in safety procedures can help mitigate risks.</p>
                
                <p><strong class="text-primary-900">6. Documentation Review:</strong> Review documents such as contracts, leases, operational manuals, incident reports, and previous claims to understand historical liabilities and patterns.</p>

                <p><strong class="text-primary-900">7. Risk Mitigation Strategies:</strong> Develop strategies to mitigate identified risks. This could involve implementing additional safety measures, updating protocols, investing in new equipment, or modifying operational processes.</p>

                <p><strong class="text-primary-900">8. Emergency Preparedness and Response Plans:</strong> Develop or review existing emergency response plans to address accidents, natural disasters, or other unforeseen events. This should include evacuation plans, communication strategies, and procedures for managing incidents.
                </p>

                <p><strong class="text-primary-900">9. Legal and Financial Analysis:</strong> Assess potential legal liabilities and financial implications associated with various risks. Consider the potential costs of litigation, compensation claims, fines, or penalties resulting from any negligence or accidents.</p>

                <p><strong class="text-primary-900">10. Continuous Monitoring and Review:</strong> Risk assessment is an ongoing process. Regularly monitor and reassess risks as the business evolves, new regulations come into effect, or as technologies change.
                </p>

                <p>It's important to involve a multidisciplinary team including risk management specialists, legal advisors, insurance professionals, and relevant subject matter experts to conduct a comprehensive liability assessment study. Additionally, consulting with experts in industrial safety standards and regulations can provide valuable insights into best practices and compliance measures for the specific industry.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/liability-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/liability-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/liability-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 5,
                title: 'Risk Inspection',
                heading: 'Why should you place your trust in us for conducting a thorough Risk Inspection?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Risk inspection</strong> is a systematic process of assessing and evaluating potential risks and hazards associated with a particular activity, process, or situation. It involves identifying and analyzing potential risks, assessing their likelihood and potential impact, and developing strategies to mitigate or manage those risks.</p>

                <p> Here are the key steps typically involved in a risk inspection process: </p>

                <p><strong class="text-primary-900">1. Identify the scope:</strong> Clearly define the scope of the inspection, whether it's a specific project, process, or an overall assessment of a workplace.</p>

                <p><strong class="text-primary-900">2.Gather information:</strong> Collect relevant information about the activity or process under inspection. This may include documents, procedures, previous incident reports, and any other relevant data.</p>

                <p><strong class="text-primary-900">3. Identify potential risks:</strong> Analyze the collected information to identify potential risks and hazards associated with the activity. Consider both internal factors (such as equipment failure, human error) and external factors (such as environmental conditions, regulatory requirements).</p>

                <p><strong class="text-primary-900">4. Assess risk likelihood and impact:</strong> Evaluate the likelihood of each identified risk occurring and the potential impact if it does. This assessment helps prioritize risks based on their severity.</p>

                <p><strong class="text-primary-900">5. Evaluate existing controls:</strong> Review the existing safety measures, protocols, and control mechanisms in place to mitigate identified risks. Assess their effectiveness and identify any gaps or areas that need improvement.</p>
                
                <p><strong class="text-primary-900">6. Develop risk mitigation strategies:</strong> Based on the assessment of risks and existing controls, develop strategies to mitigate or manage the identified risks. This may involve implementing additional safety measures, modifying processes, providing training, or establishing contingency plans.</p>

                <p><strong class="text-primary-900">7. Implement risk control measures:</strong> Put the identified risk mitigation strategies into action. This may involve implementing new protocols, conducting training sessions, or making physical modifications to the environment.</p>

                <p><strong class="text-primary-900">8. Monitor and Review:</strong> Continuously monitor the effectiveness of the implemented risk control measures. Regularly review the risk inspection process to identify any new risks or changes in existing risks. Update the inspection process and risk management strategies accordingly.
                </p>

                <p>Risk inspection is crucial in various industries, such as construction, manufacturing, healthcare, and transportation, to ensure the safety of workers, the public, and the environment. It helps organizations identify potential risks, prevent accidents and incidents, and maintain compliance with relevant regulations and standards.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/risk-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/risk-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/risk-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            }
        ],
    },
    {
        id: 'risk-assessment-and-management',
        title: 'Risk Assessment and Management',
        image: '/images/services-02.png',
        subCategories: [
            {
                id: 1,
                title: 'Business Interruption Study',
                heading: 'Why should you place your trust in us for conducting a business interruption Study?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p>A <strong class="text-primary-900">Risk inspection</strong> study is an assessment conducted by businesses to analyze the potential risks and impacts of disruptions to their operations. It involves identifying and evaluating the various factors that could interrupt normal business activities and assessing the potential financial and operational consequences.</p>

                <p>The purpose of a business interruption study is to proactively identify vulnerabilities and develop strategies to mitigate the impact of disruptions on the business. This study is especially crucial for businesses that heavily rely on continuous operations, such as manufacturing facilities, service providers, and supply chain-dependent organizations. </p>

                <p> Here are the key steps typically involved in a business interruption study: </p>

                <p><strong class="text-primary-900">1. Risk identification:</strong> Identify potential risks and threats that could disrupt business operations. This includes considering both internal and external factors such as natural disasters, power outages, equipment failures, cyberattacks, supply chain disruptions, and regulatory changes.</p>

                <p><strong class="text-primary-900">2. Impact analysis:</strong> Assess the potential consequences of each identified risk on the business. Determine the financial, operational, and reputational impacts that may occur during and after an interruption.</p>

                <p><strong class="text-primary-900">3. Business impact analysis:</strong> Analyze how different disruptions would affect various aspects of the business, such as revenue generation, customer satisfaction, employee productivity, and operational efficiency. This analysis helps prioritize critical functions and processes for continuity planning.</p>

                <p><strong class="text-primary-900">4. Risk mitigation strategies:</strong> Develop strategies to reduce the likelihood and impact of potential disruptions. This may involve implementing preventive measures, such as redundancy in critical systems, backup power supply, data backups, and disaster recovery plans.
                </p>

                <p><strong class="text-primary-900">5. Business continuity planning:</strong> Create a comprehensive plan that outlines the steps to be taken in the event of a disruption. This plan should include emergency response protocols, communication strategies, resource allocation, and alternative operating procedures to minimize downtime and ensure a timely recovery.</p>
                
                <p><strong class="text-primary-900">6. Testing and maintenance:</strong> Regularly test the business continuity plan to ensure its effectiveness and identify areas for improvement. Conduct drills and simulations to evaluate the response and recovery capabilities of the organization. Update the plan as necessary to reflect changes in the business environment.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/Business-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Business-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 2,
                title: 'Electrical Root Cause Analysis',
                heading: 'Why should you place your trust in us for conducting Electrical Root Cause Analysis?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Electrical root cause analysis (RCA) </strong> refers to the systematic process of identifying the underlying reasons or factors that contribute to electrical issues or failures within a system or equipment. It aims to determine the primary cause of a problem rather than just addressing its symptoms. RCA is crucial in preventing the recurrence of electrical failures and improving the overall reliability and safety of electrical systems.</p>

                <p>Here are the key steps involved in conducting an electrical root cause analysis: </p>

                <p><strong class="text-primary-900">1. Identify the Problem:</strong> Clearly define the electrical issue or failure that occurred. This could be equipment malfunction, power outage, electrical fire, etc.</p>

                <p><strong class="text-primary-900">2. Gather Data:</strong> Collect relevant information such as maintenance records, design specifications, operational logs, incident reports, and any available data related to the problem.</p>

                <p><strong class="text-primary-900">3. Investigate and Analyze:</strong> Use techniques like fault tracing, testing, inspection, and interviews with personnel involved to determine the sequence of events that led to the electrical problem.</p>

                <p><strong class="text-primary-900">4. Fishbone/Ishikawa Diagram:</strong> Create a cause-and-effect diagram (also known as a fishbone or Ishikawa diagram) to visualize and organize potential causes into categories such as people, process, equipment, environment, and materials.
                </p>

                <p><strong class="text-primary-900">5. Identify Root Causes:</strong> Analyze the potential causes identified in the previous step to distinguish root causes from contributing factors. Root causes are the fundamental reasons behind the problem.</p>
                
                <p><strong class="text-primary-900">6. Prioritize and Verify:</strong> Evaluate the significance of each root cause in relation to the problem. Verify the identified root causes by testing hypotheses or utilizing simulations, if necessary.</p>
                
                <p><strong class="text-primary-900">7. Develop Corrective Actions:</strong> Formulate corrective actions aimed at addressing the root causes. These actions should prevent the recurrence of the issue and improve the reliability of the electrical system.</p>

                <p><strong class="text-primary-900">8. Implement Solutions::</strong> Implement the proposed corrective actions and ensure they are effectively integrated into the system.
                </p>

                <p><strong class="text-primary-900">9. Monitor and Review:</strong> Monitor the system to ensure the effectiveness of the implemented solutions. Review and revise the RCA process if needed to improve future analyses.</p>
                
                <p><strong class="text-primary-900">10. Document and Communicate:</strong> Document the entire RCA process, including findings, actions taken, and outcomes. Communicate these findings to relevant stakeholders to ensure awareness and understanding.</p>
                
                <p>Effective electrical root cause analysis requires technical expertise, collaboration among different disciplines (such as electrical engineering, maintenance, and operations), and a structured approach to problem-solving. It's a proactive way to improve the reliability and safety of electrical systems by addressing underlying issues rather than just treating the symptoms</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/electricalroot-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalroot-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalroot-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalroot-4.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 3,
                title: 'Electrical Single Line Diagram',
                heading: 'Why should you place your trust in us for conducting a electrical single line diagram preparation project ?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p>An<strong class="text-primary-900"> Electrical Single Line Diagram (SLD) </strong> is a schematic representation of a power system that shows the connections and components in a simplified manner. It uses standardized symbols to illustrate the flow of electricity from the power source to various loads or equipment in a system. Here's a breakdown of what an SLD typically includes:</p>

                <p><strong class="text-primary-900">1. Power Sources:</strong> This may include generators, transformers, utility power lines, or alternative sources like solar panels or wind turbines. They are usually represented by specific symbols.</p>

                <p><strong class="text-primary-900">2. Distribution Equipment:</strong> Components such as circuit breakers, switches, fuses, relays, and transformers are shown in the diagram. Each type of equipment is represented by a standard symbol.</p>

                <p><strong class="text-primary-900">3. Loads:</strong> Various electrical loads connected to the system are represented, such as motors, lighting, heating, and other devices. Each load may have its symbol depending on its type and capacity.</p>

                <p><strong class="text-primary-900">4. Connectors and Wiring:</strong> Lines connecting different components represent conductors and cables carrying electrical power. These lines show the flow of electricity through the system.
                </p>

                <p><strong class="text-primary-900">5. Control and Protection Devices:</strong> SLDs may also include symbols for control devices, protection relays, meters, and other monitoring equipment used in the system.</p>
                
                <p><strong class="text-primary-900">6. Labels and Annotations:</strong> Descriptive labels and annotations are often added to the diagram to provide additional information about components, ratings, or specific details related to the system.</p>
                
                <p>The primary purpose of an SLD is to provide a clear overview of the electrical system's configuration, helping engineers, technicians, and electricians understand the system's layout and function. It aids in troubleshooting, maintenance, and planning modifications or expansions to the electrical system</p>
                
                <p>It's important to note that while SLDs offer a simplified representation of the system, more detailed engineering drawings and documentation, like wiring diagrams and equipment specifications, might be required for a comprehensive understanding of the system's operation and design</p>
                
                <p>Creating an SLD involves adhering to industry standards and conventions for symbols and representation to ensure clarity and consistency across different systems and designs</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/electricalsingle-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalsingle-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalsingle-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/electricalsingle-4.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 4,
                title: 'Lightning Protection Study Verification',
                heading: 'Why should you place your trust in us for conducting Lightning Protection Assessment?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p>A<strong class="text-primary-900">lightning protection Study Verification</strong> involves evaluating the potential risks posed by lightning strikes to a structure or facility and implementing measures to mitigate those risks. Here are some steps typically involved in a lightning protection assessment:</p>

                <p><strong class="text-primary-900">1. Risk assessment:</strong> Begin by assessing the risk associated with lightning strikes. This includes considering the location of the structure, the frequency of lightning activity in the area, the type of structure (residential, commercial, industrial, etc.), and the consequences of a lightning strike (such as damage to the building, equipment, or potential harm to occupants).</p>

                <p><strong class="text-primary-900">2. Building inspection:</strong> Conduct a thorough inspection of the structure to identify potential vulnerabilities. Look for features that could attract or conduct lightning, such as tall structures, metal components, or inadequate grounding systems. Examine the roof, lightning rods (if present), electrical systems, and any external equipment or antennas.</p>

                <p><strong class="text-primary-900">3. Lightning protection system evaluation:</strong> If the structure has an existing lightning protection system, assess its effectiveness and compliance with relevant standards (such as NFPA 780 or IEC 62305). Ensure that the system is properly installed, adequately maintained, and able to handle the anticipated lightning loads.</p>

                <p><strong class="text-primary-900">4. Lightning risk analysis:</strong> Perform a lightning risk analysis to determine the likelihood of a lightning strike and the potential consequences. This analysis may involve studying historical lightning data, conducting a site-specific lightning risk assessment, or employing specialized software tools for lightning risk modeling.
                </p>

                <p><strong class="text-primary-900">5. Mitigation measures:</strong> Based on the risk assessment and lightning risk analysis, recommend appropriate mitigation measures. These may include the installation or upgrade of lightning rods, grounding systems, surge protection devices, or lightning arrestors. Consider other protective measures such as shielding for critical electronic equipment or surge protection for power and communication lines.</p>
                
                <p><strong class="text-primary-900">6. Compliance with standards:</strong> Ensure that any lightning protection measures implemented comply with applicable standards and codes. These standards outline specific requirements for the design, installation, and maintenance of lightning protection systems.</p>
                
                <p><strong class="text-primary-900">7. Documentation and reporting:</strong> Document the findings of the lightning protection assessment, including the risk assessment, inspection results, mitigation measures, and compliance information. Prepare a comprehensive report that can be used for reference, compliance purposes, or for obtaining necessary approvals or certifications.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/lightning-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/lightning-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/lightning-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
        ],
    },
    {
        id: 'hazard-identification-and-analysis',
        title: 'HAZARD Identification and Analysis',
        image: '/images/services-03.png',
        subCategories: [
            {
                id: 1,
                title: 'HAZID (Hazard Identification)',
                heading: 'Why should you place your trust in us for conducting HAZID?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">HAZID stands for Hazard Identification</strong> which is a technique used in various industries, particularly in engineering, to systematically identify potential hazards and risks associated with a process, system, or project. It's an essential part of risk management and helps in preventing accidents and mitigating risks before they occur.</p>

                <p>HAZID studies typically involve multidisciplinary teams that examine the entire lifecycle of a project or system, looking at various stages from design to operation. The process involves brainstorming sessions, checklists, flow diagrams, and other tools to identify and evaluate potential hazards, their causes, and their possible consequences.</p>

                <p>Some steps involved in a HAZID study include:</p>

                <p><strong class="text-primary-900">1. Scope Definition:</strong> Determine the boundaries and objectives of the study.</p>

                <p><strong class="text-primary-900">2. Identifying Hazards:</strong> Brainstorming and identifying potential hazards associated with the project or system.</p>

                <p><strong class="text-primary-900">3. Assessing Risks:</strong>Evaluating the likelihood and consequences of these identified hazards.</p>

                <p><strong class="text-primary-900">4. Risk Mitigation:</strong> Developing strategies to reduce or eliminate the identified risks.</p>

                <p><strong class="text-primary-900">5. Documentation:</strong> Compiling all findings, recommendations, and action plans in a report.</p>
                
                <p>HAZID studies help organizations in making informed decisions regarding risk reduction measures, safety protocols, and designing systems that are inherently safer. These studies are often mandated in high-risk industries such as oil and gas, chemical manufacturing, aerospace, and others where safety is of utmost importance.Overall, conducting a HAZID study aids in proactive risk management, thereby enhancing safety measures and minimizing the likelihood of accidents or failures.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/Hazid-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Hazid-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 2,
                title: 'HAZOP (Hazard and Operability Study)',
                heading: 'Why should you place your trust in us for conducting HAZOP ?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">HAZOP stands for Hazard and Operability Study</strong> It is a systematic and structured technique used in various industries, particularly in the process and chemical engineering fields, to identify and analyze potential hazards and operational issues associated with a system, process, or plant.</p>

                <p>The main objective of a HAZOP study is to systematically examine the design and operation of a system to identify possible deviations from the intended design, potential hazards, and operability problems. By conducting a HAZOP study, engineers and safety professionals can assess the risks involved and propose preventive and mitigating measures to enhance safety, reliability, and efficiency.</p>

                <p>Here are some key points about the HAZOP process:</p>

                <p><strong class="text-primary-900">1. Study Team:</strong> A multidisciplinary team is typically assembled, including process engineers, safety professionals, operations personnel, and other relevant experts.</p>

                <p><strong class="text-primary-900">2. Guide Words:</strong> The team uses a set of predefined guide words (e.g., No, More, Less, Reverse, etc.) to systematically challenge each part of the system under study. These guide words help in identifying potential deviations and their consequences.</p>

                <p><strong class="text-primary-900">3. Nodes:</strong>The system under examination is divided into smaller sections called nodes, which could be process units, equipment, or any other relevant component.</p>

                <p><strong class="text-primary-900">4. Deviations and Consequences:</strong> The team systematically explores each node and applies the guide words to identify possible deviations from the intended design or operation. They then evaluate the potential consequences of these deviations, including safety hazards, environmental impact, and operational issues.</p>

                <p><strong class="text-primary-900">5. Risk Assessment:</strong>The identified deviations and consequences are assessed in terms of their severity, likelihood of occurrence, and detectability. This helps prioritize the risks and determine the necessary actions to mitigate them.</p>

                <p><strong class="text-primary-900">6. Recommendations:</strong> Based on the findings of the HAZOP study, the team develops recommendations and proposes modifications to the system design, operating procedures, safety systems, or other measures to reduce the identified risks and enhance overall system performance.
                </p>

                <p><strong class="text-primary-900">7. Risk Assessment:</strong>The HAZOP study process and its results are thoroughly documented, including the identified hazards, risk assessments, recommendations, and actions taken. This documentation serves as a valuable reference for future reviews and audits.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/HAZOP-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/HAZOP-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 3,
                title: 'HIRA (Hazard Identification and Risk Assessment)',
                heading: 'Why should you place your trust in us for conducting HIRA  ?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Hazard Identification and Risk Analysis (HIRA)</strong> is a systematic approach used to identify potential hazards and analyze associated risks within a particular environment or process. It's a critical component of risk management in various industries, including manufacturing, construction, healthcare, and more.</p>

                <p>Here are the primary steps involved in HIRA:</p>

                <p><strong class="text-primary-900">1. Hazard Identification:</strong>This step involves identifying and recognizing potential sources of harm or danger within a workplace, system, or activity. Hazards can be classified into various categories such as physical, chemical, biological, ergonomic, and psychosocial.</p>

                <p><strong class="text-primary-900">2. Risk Assessment</strong> Once hazards are identified, the next step is to assess the risks associated with each hazard. This involves evaluating the likelihood of the hazard causing harm and the potential severity of that harm. Risk assessment often involves using qualitative or quantitative methods to prioritize risks based on their severity.</p>

                <p><strong class="text-primary-900">3. Risk Control Measures:</strong>After identifying and assessing the risks, control measures are implemented to mitigate or eliminate these risks. These measures could include engineering controls, administrative controls, or personal protective equipment (PPE).</p>

                <p><strong class="text-primary-900">4. Review and Monitoring:</strong> Continuous monitoring and periodic review of the implemented control measures are essential to ensure their effectiveness. Regular reassessment helps in identifying new hazards that might arise and evaluating the efficacy of existing risk control measures.</p>

               <p><strong class="text-primary-900">HIRA</strong> aims to minimize or eliminate risks that could lead to accidents, injuries, or health hazards within a workplace or a specific operational environment. It's a proactive approach that helps organizations create safer work environments, protect their employees, and prevent potential losses or damages.</p>
               
               <p><strong class="text-primary-900">HIRA</strong> methodologies may vary across industries and contexts, but the core principles involve systematically identifying hazards, assessing risks, implementing control measures, and regularly reviewing and updating the risk management process.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/HIRA-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/HIRA-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/HIRA-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 4,
                title: 'QRA Study (Quantitative Risk Assessment)',
                heading: 'Why should you place your trust in us for Quantitative Risk Assessment?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Quantitative Risk Assessment (QRA)</strong>is a systematic process used to quantify and evaluate potential risks associated with various hazards in industries such as manufacturing, oil and gas, chemical processing, and other high-risk sectors. It involves a mathematical and statistical approach to assessing risks by assigning numerical values to different components of risk, including the probability of an event occurring and the magnitude of its consequences.</p>

                <p>Key components of QRA include:</p>

                <p><strong class="text-primary-900">1. Risk Identification:</strong>Identifying and understanding potential hazards, such as equipment failures, chemical spills, natural disasters, or human errors, that could lead to adverse events.</p>

                <p><strong class="text-primary-900">2. Risk Analysis:</strong> Evaluating the probability of these events occurring and the possible consequences they might have on personnel, the environment, assets, and operations. This phase often involves utilizing tools like fault tree analysis, event tree analysis, and Monte Carlo simulations to model and estimate potential outcomes.</p>

                <p><strong class="text-primary-900">3. Risk Evaluation:</strong>Combining the results of the analysis to assess the overall level of risk, typically by comparing calculated risks against predetermined risk criteria or tolerances.</p>

                <p><strong class="text-primary-900">4. Risk Mitigation:</strong> Developing strategies and measures to reduce or manage identified risks. This may involve implementing safety protocols, improving equipment reliability, modifying processes, or establishing emergency response plans.</p>

                <p>Quantitative Risk Assessment is particularly valuable in high-risk industries where the consequences of accidents or failures can be severe. It provides a structured framework for decision-making, allowing organizations to allocate resources effectively to reduce the likelihood and impact of potential risks</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/QRA-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/QRA-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/QRA-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 5,
                title: 'LOPA (Layer of Protection Analysis)',
                heading: 'Why should you place your trust in us for conducting LOPA ?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Layer of Protection Analysis (LOPA)</strong>is a widely used semi-quantitative tool in the field of risk assessment and hazard analysis within industries such as chemical, process, and oil and gas. LOPA is employed to evaluate and analyze potential risks associated with industrial processes, identifying various layers of protection that mitigate or prevent those risks from causing harm to people, the environment, or assets.</p>

                <p>Here's an overview of how LOPA works:</p>

                <p><strong>Purpose of LOPA:</strong></p>

                <p><strong class="text-primary-900">1. Risk Assessment:</strong>LOPA helps in assessing the risk associated with a particular process or operation.</p>

                <p><strong class="text-primary-900">2. Identifying Safeguards:</strong> It identifies and evaluates layers of protection to mitigate risks.
                Decision Making: It aids in making informed decisions about risk reduction measures.</p>

                <p><strong>Key Steps in LOPA:</strong></p>

                <p><strong class="text-primary-900">1. Risk Identification:</strong>Begin by identifying various potential hazards and scenarios within a process that could lead to unwanted events or consequences.</p>

                <p><strong class="text-primary-900">2. Scenario Evaluation:</strong> Each identified scenario is analyzed in terms of its consequences and likelihood of occurrence.</p>

                <p><strong class="text-primary-900">3. Determining Initiating Events:</strong> Identify the initiating events that might lead to these scenarios.</p>

                <p><strong class="text-primary-900">4. Defining Independent Protection Layers (IPLs):</strong> Identify and list the existing safeguards or protection layers already in place. These can include safety systems, alarms, procedures, etc.</p>

                <p><strong class="text-primary-900">5. Assessing Layer Effectiveness:</strong> Evaluate the effectiveness of each independent protection layer (IPL) in preventing or mitigating the consequences of the identified scenarios.</p>

                <p><strong class="text-primary-900">6. Calculating Risk:</strong> Use semi-quantitative methods to estimate the risk associated with each scenario, considering the likelihood of the initiating event and the effectiveness of the safeguards.</p>

                <p><strong class="text-primary-900">7. Decision Making:</strong> Based on the calculated risk, determine whether additional safeguards or risk reduction measures are necessary. This involves comparing the calculated risk against predefined risk tolerability criteria.</p>

                <p> <strong class="text-primary-900"> 7 Advantages of LOPA:</strong> </p>
                <ul> 
                <li>Helps in prioritizing and focusing resources on critical risks.</li>
                <li>Provides a structured approach to analyze risk scenarios.</li>
                <li>Facilitates decision-making by quantifying risk to some extent.</li>
                <li>Enhances understanding of process safety and potential areas of improvement</li>
                </ul>`,
                banners: [
                    {
                        image: '/images/servicesslider/LOPA-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/LOPA-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/LOPA-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/LOPA-4.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 6,
                title: 'NATCAT EXPOSURE ANALYSIS',
                heading: 'Why should you place your trust in us for conducting a NAT CAT Exposure Analysis?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">NAT CAT EXPOSURE ANALYSIS </strong>, also known as Natural Catastrophe exposure analysis, is a process of assessing the potential risks and financial impact associated with natural disasters or catastrophes. It involves analyzing the exposure of an entity, such as an insurance company, a portfolio of assets, or a geographic region, to various types of natural perils, including hurricanes, earthquakes, floods, wildfires, and other extreme weather events.</p>

                <p>The goal of NAT CAT exposure analysis is to evaluate the likelihood and severity of potential losses due to natural disasters. It typically involves several steps:</p>

                <p><strong class="text-primary-900">1. Data collection:</strong>Gathering relevant data on historical events, including their frequency, severity, and spatial distribution. This can include historical weather data, geological information, and records of past natural disasters.</p>

                <p><strong class="text-primary-900">2. Risk modeling:</strong> Using advanced modeling techniques, such as catastrophe models, to simulate and quantify the potential impact of natural catastrophes. These models consider various factors, including geographical location, building characteristics, population density, and climate patterns, to estimate the probability and severity of losses.</p>

                <p><strong class="text-primary-900">3. Exposure assessment:</strong>Identifying and assessing the assets or portfolios at risk within the defined geographic area. This can include evaluating the vulnerability of structures, infrastructure, and other assets to specific natural perils.</p>

                <p><strong class="text-primary-900">4. Financial analysis:</strong> Estimating the potential financial impact of natural catastrophes on the exposed assets. This involves quantifying potential losses, considering factors such as insurance coverage, deductibles, and policy limits.</p>

                <p><strong class="text-primary-900">4. Risk mitigation:</strong> Identifying strategies to reduce exposure and mitigate potential losses. This can include implementing preventive measures, such as building codes, infrastructure improvements, land-use planning, and risk transfer mechanisms like insurance or reinsurance.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/NATCAT-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/NATCAT-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/NATCAT-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            }
        ],
    },
    {
        id: 'safety-systems-and-design',
        title: 'Safety Systems and Design',
        image: '/images/services-04.png',
        subCategories: [
            {
                id: 1,
                title: 'Fire Protection System Design & Review',
                heading: 'Why should you place your trust in us for conducting a fire protection system design & review?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Fire protection system</strong> design and review involves the planning, layout, and assessment of various fire protection measures within a building or facility. These systems are essential for ensuring the safety of occupants and minimizing property damage in the event of a fire. Here are some key considerations and steps involved in the process.</p>

                <p><strong class="text-primary-900">1. Identify applicable codes and standards:</strong>Fire protection system designs must comply with local building codes, fire codes, and relevant industry standards. These codes outline the requirements for different types of occupancies and provide guidelines for system design and installation.</p>

                <p><strong class="text-primary-900">2. Conduct a fire risk assessment:</strong> Assess the specific fire risks associated with the building or facility. Consider factors such as occupancy type, building materials, fire load, and the presence of hazardous materials. This assessment helps determine the appropriate fire protection measures needed.</p>

                <p><strong class="text-primary-900">3. Determine system requirements:</strong>Based on the fire risk assessment, identify the required fire protection systems and features. Common systems include fire alarm systems, fire sprinkler systems, fire suppression systems (e.g., gas-based or foam-based), fire extinguishers, emergency lighting, and evacuation systems.</p>

                <p><strong class="text-primary-900">4. Design the fire protection system:</strong> Engage with a qualified fire protection engineer or consultant to design the system. The design should consider the building layout, occupancy classifications, potential fire sources, and evacuation routes. The engineer will determine the appropriate placement of devices, piping, wiring, control panels, and other system components.</p>

                <p><strong class="text-primary-900">5. Select equipment and components:</strong> Choose appropriate fire protection equipment and components that meet the design requirements and comply with applicable codes and standards. Ensure that the selected devices are tested and listed by recognized testing laboratories.</p>
                
                <p><strong class="text-primary-900">6. Hydraulic calculations and analysis:</strong>For fire sprinkler systems, hydraulic calculations are performed to determine pipe sizes, water flow rates, and pressure requirements. These calculations ensure that the system can adequately suppress a fire and deliver water to all areas as needed.</p>
                
                <p><strong class="text-primary-900">7. System installation:</strong> The fire protection system should be installed by qualified contractors following the design specifications and manufacturer's instructions. Regular inspections by the authority having jurisdiction (AHJ) may be required during installation to ensure compliance.</p>
                
                <p><strong class="text-primary-900">8. Testing and commissioning:</strong> Once the system is installed, it should undergo thorough testing and commissioning. This includes functional testing of all devices, flow testing of sprinklers, alarm tests, and coordination with the central monitoring station if applicable.</p>
                
                <p><strong class="text-primary-900">9. Documentation and record-keeping:</strong> Maintain detailed documentation of the fire protection system design, installation, and testing records. These records are crucial for ongoing system maintenance, inspections, and future modifications.</p>
                
                <p><strong class="text-primary-900">10. Periodic review and maintenance:</strong> Regularly review and assess the fire protection system to ensure it remains effective and up to code. Conduct routine maintenance, inspections, and testing as per manufacturer recommendations and local regulations.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/firepro-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/firepro-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/firepro-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/firepro-4.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 2,
                title: 'Safety Integrity Level',
                heading: 'Why should you place your trust in us for conducting a thorough Safety Integrity Level (SIL) Assessment & Verification?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and TestimonialsContinuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Safety Integrity Level (SIL)</strong> assessment and verification are crucial processes in ensuring the safety of systems, particularly in industries where the consequences of system failures can have severe impacts on people, the environment, or assets. SIL is a measure of the reliability of safety functions within a system to reduce risks to an acceptable level.</p>

                <p>Here are the key steps involved in SIL assessment and verification:</p>

                <p><strong class="text-primary-900">1. Identify Safety Requirements:</strong> Begin by identifying the safety functions that are critical for mitigating specific hazards. Determine the required risk reduction for each hazard and establish the SIL targets for the safety functions.</p>

                <p><strong class="text-primary-900">2. Hazard Analysis:</strong> Conduct a thorough hazard analysis such as Hazard and Operability Study (HAZOP), Fault Tree Analysis (FTA), or Failure Mode and Effects Analysis (FMEA) to identify potential hazards, their causes, and the consequences of failures.</p>

                <p><strong class="text-primary-900">3. Assign SIL Levels:</strong> Use recognized methods (such as IEC 61508 or IEC 61511 standards) to assign SIL levels to safety functions based on their importance in mitigating the identified hazards and the required risk reduction.</p>

                <p><strong class="text-primary-900">4. SIL Verification:</strong> Verify whether the safety functions and systems meet the specified SIL requirements. This involves ensuring that the design, components, and procedures comply with the SIL targets. Verification may include reliability calculations, fault tolerance analysis, and safety system architecture assessment.</p>

                <p><strong class="text-primary-900">5. Documentation and Compliance:</strong> Proper documentation of the SIL assessment process, verification results, and the rationale behind SIL allocations is critical. Ensure compliance with relevant industry standards and regulations.</p>
                
                <p><strong class="text-primary-900">6. Continuous Improvement:</strong>SIL assessment is not a one-time process. Continuous monitoring, periodic reviews, and reassessment are necessary to ensure that the safety functions maintain their integrity throughout the lifecycle of the system.</p>
                
                <p><strong class="text-primary-900">7. Independent Assessment:</strong> In many cases, an independent assessment by third-party experts or certification bodies may be required to validate the SIL assessment and verification process.</p>
                
                <p><strong class="text-primary-900">8. Training and Competence:</strong> Ensure that personnel involved in SIL assessment and verification processes are adequately trained and possess the necessary skills and competence to perform their roles effectively.</p>
                
                <p>By following these steps, organizations can systematically evaluate the safety integrity of their systems, ensuring that they meet the required safety levels and effectively mitigate potential risks.</p> `,
                banners: [
                    {
                        image: '/images/servicesslider/Safetylevel-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Safetylevel-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Safetylevel-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 3,
                title: 'Structural Safety Audit',
                heading: 'Why should you place your trust in us for conducting Structural Safety Audit ?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p>A <strong class="text-primary-900">Structural Safety Audit</strong> is a comprehensive examination and assessment of the structural integrity and safety of a building, infrastructure, or any physical structure. The primary goal of such an audit is to evaluate the structure's condition, identify potential hazards, weaknesses, or deficiencies, and recommend necessary measures to enhance safety and prevent failures or accidents.</p>

                <p>Here are the key components and steps involved in conducting a Structural Safety Audit:</p>

                <p><strong class="text-primary-900">1. Initial Assessment:</strong> This involves gathering information about the structure, including its design specifications, construction materials used, age, maintenance history, and any previous structural issues or modification.</p>

                <p><strong class="text-primary-900">2. Site Inspection:</strong> A thorough physical examination of the structure is conducted by qualified engineers or inspectors. This involves assessing the building's foundation, load-bearing elements, beams, columns, walls, roofs, and other critical components.</p>

                <p><strong class="text-primary-900">3. Testing and Analysis:</strong> Structural testing methods such as non-destructive testing (NDT), including ultrasonic testing, x-rays, or load testing, might be employed to assess the integrity of materials and detect hidden flaws or weaknesses.</p>

                <p><strong class="text-primary-900">4. Documentation and Analysis:</strong> All findings from the inspection and testing are documented and analyzed to determine the current condition of the structure and to identify any areas of concern or potential risks.</p>

                <p><strong class="text-primary-900">5. Risk Assessment:</strong> Engineers evaluate the identified issues and assess the level of risk associated with each, considering factors like potential for failure, safety hazards, and the likelihood of adverse events.</p>
                
                <p><strong class="text-primary-900">6. Recommendations and Remedial Measures:</strong>Based on the assessment, a report is generated outlining recommendations for remedial actions to address the identified structural deficiencies. These may include repairs, reinforcements, retrofitting, or even complete replacement of critical elements.</p>
                
                <p><strong class="text-primary-900">7. Implementation of Recommendations:</strong> Once the recommendations are approved, appropriate actions are taken to implement the suggested measures. This might involve repairs by qualified contractors or engineers overseeing the necessary structural modifications.</p>
                
                <p><strong class="text-primary-900">8. Follow-up and Monitoring:</strong> Regular monitoring and follow-up inspections may be recommended to ensure that the implemented measures are effective in enhancing the structural safety and integrity over time.</p>
                
                <p>Structural Safety Audits are crucial for ensuring the safety of occupants and the public, as well as for avoiding potential risks of structural failures, especially in older buildings or structures that have undergone changes or lack proper maintenance. These audits are typically conducted by qualified structural engineers or professionals with expertise in structural integrity assessments.</p> `,
                banners: [
                    {
                        image: '/images/servicesslider/Safetyaudit-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Safetyaudit-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Safetyaudit-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 4,
                title: 'Risk Management Planning',
                heading: 'Why should you place your trust in us for conducting a thorough Risk Management Planning?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Risk management planning</strong> is the process of identifying, assessing, prioritizing, and mitigating risks in order to minimize the negative impact they can have on a project, organization, or any other endeavor. It involves developing a comprehensive strategy to effectively manage risks and ensure the success of the undertaking.</p>

                <p>Here are the key steps involved in risk management planning:</p>

                <p><strong class="text-primary-900">1. Risk Identification:</strong> Identify potential risks that could affect the project or organization. This can be done through various techniques such as brainstorming, reviewing historical data, conducting interviews, and analyzing similar projects.</p>

                <p><strong class="text-primary-900">2. Risk Assessment:</strong> Evaluate the identified risks to determine their likelihood of occurrence and potential impact. This step involves analyzing the probability and severity of each risk and assigning it a risk rating or priority level.</p>

                <p><strong class="text-primary-900">3. Risk Prioritization:</strong> Rank the risks based on their significance and prioritize them for further analysis and action. High-priority risks are those with a high probability of occurrence and significant potential impact on the project or organization.</p>

                <p><strong class="text-primary-900">4. Risk Mitigation Strategies:</strong> Develop strategies to manage and mitigate the identified risks. This may include Risk Avoidance (eliminating the Risk Altogether), Risk Transfer (shifting the risk to another party), Risk Reduction (implementing measures to reduce the likelihood or impact of the risk), or Risk Acceptance (acknowledging the risk and preparing contingency plans).</p>

                <p><strong class="text-primary-900">5. Risk Response Planning:</strong> Determine the specific actions and contingency plans that will be implemented in response to each identified risk. This involves outlining the steps, responsibilities, and resources required to address the risks effectively.</p>
                
                <p><strong class="text-primary-900">6. Risk Monitoring and Control:</strong> Continuously monitor the identified risks throughout the project or organizational operations. Regularly assess the effectiveness of the implemented risk management strategies and make adjustments as necessary.</p>
                
                <p><strong class="text-primary-900">7. Communication and Documentation:</strong> Maintain clear and open communication regarding the identified risks, mitigation strategies, and their progress with relevant stakeholders. Document all aspects of the risk management process, including risk registers, risk action plans, and any changes made during the project.</p>
                
                <p><strong class="text-primary-900">8. Review and Update:</strong> Regularly review and update the risk management plan as new risks arise or existing risks change in significance. Risk management is an ongoing process that requires periodic reassessment and adjustment.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/Riskplaning-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Riskplaning-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
        ],
    },
    {
        id: 'training-project-support-and-analysis',
        title: 'Training, Project Support and Analysis',
        image: '/images/services-05.png',
        subCategories: [
            {
                id: 1,
                title: 'Incident Accident Investigation',
                heading: 'Why should you place your trust in us for conducting Incident/Accident Investigation ?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Incident Accident Investigation</strong> are crucial processes aimed at understanding the root causes of an unexpected event, whether in a workplace, public space, or other settings. These investigations help prevent future occurrences and improve safety measures. Here's a breakdown of the process.</p>

                <p><strong class="text-primary-900">Incident vs. Accident:</strong></p>

                <p><strong class="text-primary-900">1. Incident:</strong> An incident is any unplanned event that disrupts normal operations or has the potential to do so. It might or might not result in injury, damage, or loss. For instance, a near-miss, a spill, or a small injury could be considered incidents.</p>

                <p><strong class="text-primary-900">2. Accident:</strong> An accident is a specific type of incident that results in injury, damage, or loss. It involves harm to people, property, or the environment.</p>

                <p><strong class="text-primary-900">3. Investigation Process:</strong> Reporting the Incident/Accident: The first step is reporting the incident or accident promptly. This could involve notifying supervisors, filling out incident report forms, or following specific protocols established by the organization. Securing the Scene: Ensure that the area is safe and secure to prevent further accidents or injuries. This might involve temporarily shutting down machinery, cordoning off an area, or taking other necessary measures.</p>

                <p><strong class="text-primary-900">4. Collecting Information:</strong> Gather as much information as possible. This could include eyewitness accounts, photographs, physical evidence, relevant documents, and any other data related to the incident or accident.</p>

                <p><strong class="text-primary-900">5. Analysis and Root Cause Identification:</strong> Analyze the collected information to identify the root causes of the incident or accident. This involves looking beyond the immediate cause to uncover underlying factors like human error, equipment failure, organizational issues, or procedural weaknesses.</p>
                
                <p><strong class="text-primary-900">6. Developing Corrective Actions:</strong> Once the root causes are identified, develop corrective actions to address these issues and prevent similar incidents from happening in the future. This could involve implementing new safety protocols, providing additional training, improving equipment maintenance, or revising procedures.</p>
                
                <p><strong class="text-primary-900">7. Report and Documentation:</strong> Prepare a comprehensive report detailing the incident or accident, the findings of the investigation, the root causes identified, and the proposed corrective actions. This documentation is vital for organizational learning and regulatory compliance.</p>
                
                <p><strong class="text-primary-900">8. Implementation and Follow-up:</strong> Implement the recommended corrective actions and monitor their effectiveness. Follow up regularly to ensure that the changes made are effective in preventing similar incidents.</p>
                
                <p><strong class="text-primary-900"> Key Principles: </strong> </p>
                <p><strong class="text-primary-900">Thoroughness: </strong> Investigate all incidents, regardless of severity, to uncover root causes and prevent future occurrences.</p>
                <p><strong class="text-primary-900">Timeliness: </strong> Conduct investigations promptly to ensure accurate information and prevent potential hazards from persisting.</p>
                <p><strong class="text-primary-900">Learning and Improvement: </strong> Use investigation findings to improve safety measures, procedures, and training within the organization. The goal of incident/accident investigation is not to assign blame but to understand what happened, why it happened, and how to prevent it from happening again in the future.</p>`,
                banners: [
                    {
                        image: '/images/servicesslider/Incident-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Incident-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Incident-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 2,
                title: 'PML Calculation',
                heading: 'Why should you place your trust in us for conducting a PML Calculation Study ?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Probable Maximum Loss (PML)</strong> is a term commonly used in the insurance industry, particularly in the context of property insurance and risk assessment. It represents the maximum expected loss that could be incurred by an insurer or property owner due to a catastrophic event, such as a natural disaster or major accident, within a specified period of time.</p>

                <p>PML is calculated based on various factors, including:</p>
                
                <p><strong class="text-primary-900">Property Value: </strong> The total value of the insured property or assets.</p>

                <p><strong class="text-primary-900">Probability of a Hazard: </strong> The likelihood of a specific catastrophic event occurring, such as earthquake, flood, hurricane, fire, etc.</p>

                <p><strong class="text-primary-900">Vulnerability Assessment: </strong> The susceptibility of the property to damage or destruction based on its location, construction, structural integrity, and other relevant factors.</p>

                <p><strong class="text-primary-900">Exposures: </strong> The extent to which the property is exposed to potential risks.</p>

                <p><Insurers use PML assessments to evaluate and manage their potential financial exposure to significant losses. This estimation helps insurers determine the appropriate level of coverage and pricing for insurance policies. Additionally, property owners can use PML assessments to understand the potential financial impact of catastrophic events and take necessary risk mitigation measures.</p>

                <p>PML is usually expressed as a percentage of the total value of the insured property, representing the worst-case scenario loss that could be experienced within a specific time frame, such as a year. </p>
                
                <p>It's important to note that PML calculations involve various assumptions and uncertainties since they are based on probabilities and models, making them subject to change based on updated data or changes in risk factors. </p>`,
                banners: [
                    {
                        image: '/images/servicesslider/PML-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/PML-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 3,
                title: 'Safety Training & Mock Drills',
                heading: 'Why should you place your trust in us for conducting a fire protection system design & review?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Safety Training and Mock Drills</strong> are important practices for preparing individuals and organizations to respond effectively to emergencies and ensure the safety of people and property. These activities help familiarize participants with emergency procedures, improve their response capabilities, and identify areas for improvement in the safety protocols.</p>

                <p>Here are some key points to consider regarding Safety Training and Mock Drills:</p>
                
                <p><strong class="text-primary-900">Importance of Safety Training: </strong></p>

                <ul>
                <li>Safety training is crucial for creating awareness about potential hazards and the necessary actions to mitigate risks</li>
                <li>It helps employees or participants understand safety protocols, emergency procedures, and their roles and responsibilities during an emergency.</li>
                <li>Training enhances preparedness, reduces panic, and promotes a culture of safety within the organization.</li>
                </ul>
                
                <p><strong class="text-primary-900">Types of Safety Training:</strong></p>

                <p><strong class="text-primary-900">General Safety Training:</strong> This includes basic safety principles, hazard identification, and general emergency procedures applicable to all employees or participants.</p>

                <p><strong class="text-primary-900">Job-Specific Safety Training:</strong> Tailored training that focuses on the specific safety concerns and hazards associated with particular job roles or tasks.</p>

                <p><strong class="text-primary-900">Specialized Safety Training:</strong> Training for handling specialized equipment, working in hazardous environments, or addressing industry-specific safety concerns.</p>

                <p><strong class="text-primary-900">Elements of Safety Training:</strong></p>

                <p><strong class="text-primary-900">Classroom Instruction: </strong>Providing theoretical knowledge through presentations, lectures, videos, and interactive sessions.</p>

                <p><strong class="text-primary-900">Practical Demonstrations: </strong>Hands-on training to reinforce theoretical concepts, such as using fire extinguishers or practicing first aid techniques.</p>

                <p><strong class="text-primary-900">Role-Playing Exercises: </strong> Simulating emergency scenarios to allow participants to practice their response and decision-making skills.</p>

                <p><strong class="text-primary-900">Mock Drills:</strong></p>
                <ul>
                <li>
                Mock drills are simulated emergency scenarios conducted to assess the effectiveness of safety procedures and the response capabilities of individuals or teams.
                </li>
                <li>
                Common types of mock drills include fire drills, evacuation drills, active shooter drills, medical emergency drills, and natural disaster drills.
                Mock drills should be planned in advance, communicated to participants, and conducted at regular intervals to reinforce training and identify areas for improvement.
                </li>
                <li>
                After each mock drill, it is essential to conduct debriefing sessions to discuss observations, address shortcomings, and revise emergency plans if necessary.
                </li>
                </ul>

                <p><strong class="text-primary-900">Benefits of Safety Training and Mock Drills:</strong></p>

                <p><strong class="text-primary-900">Enhanced Preparedness:</strong> Training and drills equip participants with the knowledge and skills needed to respond effectively during emergencies.</p>
                
                <p><strong class="text-primary-900">Risk Reduction:</strong> Familiarity with safety protocols and procedures helps minimize risks and potential accidents in the workplace or other environments.</p>
                
                <p><strong class="text-primary-900">Improved Response Time:</strong> Practicing emergency scenarios improves response time and reduces confusion or panic in real-life situations.</p>
                
                <p><strong class="text-primary-900">Continuous Improvement: </strong>Regular training and drills allow organizations to identify weaknesses in their emergency plans and implement necessary improvements.</p>
                
                <p><strong class="text-primary-900">Compliance: </strong>Safety Training and Mock Drills may be required by law or regulatory bodies, ensuring organizations meet the necessary safety standards.</p>

                `,
                banners: [
                    {
                        image: '/images/servicesslider/SafetyTraining-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/SafetyTraining-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    
                ]
            },
            {
                id: 4,
                title: 'Process Safety Management',
                heading: 'Why should you place your trust in us for conducting a thorough Process Safety Management ?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p><strong class="text-primary-900">Process Safety Management (PSM)</strong> is a framework implemented in industries dealing with hazardous chemicals or processes to prevent major incidents, such as explosions, fires, toxic releases, and other catastrophic events. It's primarily focused on identifying, assessing, and mitigating risks associated with these processes to ensure the safety of workers, the community, and the environment.
                </p>

                <p>Key elements of Process Safety Management typically include:</p>

                <p><strong class="text-primary-900">Process Safety Culture: </strong>Establishing a culture where safety is prioritized, from top management to front-line workers, fostering a shared commitment to safety protocols and practices.</p>

                <p><strong class="text-primary-900">Process Safety Information:</strong> Comprehensive documentation of the chemicals, technology, equipment, and procedures used in the processes, including their hazards and operating parameters.</p>

                <p><strong class="text-primary-900">Process Hazard Analysis (PHA):</strong> Identifying and assessing potential hazards in the system using techniques such as HAZOP (Hazard and Operability Study), FMEA (Failure Modes and Effects Analysis), or What-If analysis.</p>

                <p><strong class="text-primary-900">Operating Procedures: </strong>Clear and concise instructions for safely conducting activities involved in the process, including startup, shutdown, and emergency operations.</p>

                <p><strong class="text-primary-900">Training: </strong>Proper training and education for all personnel involved in operating, maintaining, or overseeing the processes to ensure they understand the risks and safety procedures.</p>

                <p><strong class="text-primary-900">Mechanical Integrity:</strong> Ensuring equipment reliability and integrity through inspection, testing, and maintenance to prevent equipment failures that could lead to accidents.</p>

                <p><strong class="text-primary-900">Management of Change:</strong> A structured process to evaluate and manage changes to equipment, technology, procedures, or personnel to maintain safety and prevent unintended consequences.</p>

                <p><strong class="text-primary-900">Incident Investigation:</strong> A system for investigating incidents to understand their root causes and implement corrective actions to prevent similar occurrences in the future.</p>

                <p><strong class="text-primary-900">Emergency Planning and Response: </strong>Establishing protocols and procedures for responding to emergencies and ensuring that personnel are trained to effectively handle them.</p>

                <p><strong class="text-primary-900">Auditing and Continuous Improvement: </strong>Regular evaluations, audits, and performance reviews to identify areas for improvement and ensure ongoing compliance with safety standards and regulations.</p>

                <p>Industries such as chemical manufacturing, oil refining, pharmaceuticals, and others dealing with hazardous materials typically adhere to Process Safety Management guidelines to mitigate risks and ensure the safety of their operations.</p>

                <p>Regulatory bodies in various countries often have standards and requirements related to Process Safety Management that companies must comply with to ensure safe operations and prevent major accidents.</p>

                `,
                banners: [
                    {
                        image: '/images/servicesslider/ProcessSafety-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/ProcessSafety-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/ProcessSafety-3.jpg',
                        title: 'Fire Safety Audit'
                    }
                    
                ]
            },
            {
                id: 5,
                title: 'Project Monitoring',
                heading: 'Why should you place your trust in us for project Monitoring work ?',
                headingText: `Expertise and Experience, Transparency and Accuracy, Compliance and Standards, Client-Centric Approach, Track Record and Testimonials, Continuous Improvement, Ethics and Integrity`,
                content: `<p>A <strong class="text-primary-900">Project Monitoring</strong> is an essential aspect of project management that involves overseeing and tracking the progress, performance, and activities of a project. Its primary goal is to ensure that the project stays on track, meets its objectives, stays within the defined scope, timeline, and budget, and addresses any issues or risks that may arise during its execution.</p>

                <p>Key components of Project Monitoring include:</p>

                <p>A <strong class="text-primary-900">Progress Tracking:</strong> Monitoring the project's actual progress against the planned schedule and milestones. This involves regularly updating project timelines, checking completed tasks, and assessing how they align with the project schedule.</p>

                <p>A <strong class="text-primary-900">Performance Measurement:</strong> Evaluating the quality of work being delivered, comparing it to predefined standards or benchmarks, and ensuring that it meets the project's requirements.</p>

                <p>A <strong class="text-primary-900">Resource Management: </strong>Keeping an eye on the allocation and utilization of resources (such as finances, personnel, materials) to ensure they are used efficiently and effectively.</p>

                <p>A <strong class="text-primary-900">Risk Management: </strong>Identifying, assessing, and addressing any potential risks or issues that might impact the project's success. This involves creating risk mitigation strategies and implementing them as necessary.</p>

                <p>A <strong class="text-primary-900">Communication and Reporting:</strong> Regularly communicating with stakeholders, team members, and other relevant parties to provide updates on the project's status. Reporting on progress, challenges, and proposed solutions is a critical aspect of Project Monitoring.</p>

                <p>A <strong class="text-primary-900">Adherence to Plans and Scope:</strong> Ensuring that the project stays within its defined scope and adheres to the initial plans, making adjustments as needed while keeping the project objectives in focus.</p>

                <p>Tools like Gantt charts, project management software, progress reports, and regular meetings help facilitate Project Monitoring. Effective Monitoring allows project managers and stakeholders to identify issues early, make informed decisions, and take corrective actions to keep the project on track towards successful completion.</p>

                `,
                banners: [
                    {
                        image: '/images/servicesslider/Project-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Project-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Project-3.jpg',
                        title: 'Fire Safety Audit'
                    },
                ]
            },
            {
                id: 6,
                title: 'Asset Valuation',
                heading: 'Why should you place your trust in us for conducting Valuation of Property?',
                headingText: `Expertise and Experience - Advanced Technology - Comprehensive Analysis, Industry Knowledge - Commitment to Quality - Client Satisfaction - Regulatory Compliance`,
                content: `<p><strong class="text-primary-900">Valuing a property </strong> for insurance purposes involves determining the cost to rebuild or replace the property in case of damage or loss..</p>

                <p>There are several methods used to estimate the value of a property for insurance:</p>

                <p><strong class="text-primary-900">Market Value:</strong> This is the value of the property based on what a buyer would pay for it in its current condition. However, for insurance purposes, the market value might not accurately represent the cost of rebuilding the property.</p>

                <p><strong class="text-primary-900">Replacement Cost:</strong> This is the estimated cost to rebuild the property from scratch if it were destroyed. It includes materials, labor, and other associated costs. Insurance companies often use this method to determine coverage limits.</p>

                <p><strong class="text-primary-900">Actual Cash Value (ACV):</strong> This method considers the property's depreciated value. It takes into account the property's age, condition, and wear and tear, and then subtracts depreciation from the replacement cost.</p>

                <p><strong class="text-primary-900">Square Footage Cost:</strong> This involves calculating the cost per square foot to rebuild similar properties in the area and applying it to the property's size.</p>

                <p>To determine the most accurate value for insurance purposes, it's recommended to consider hiring a professional appraiser or using online tools provided by insurance companies that help estimate replacement costs. These tools consider various factors including the property's features, construction materials, location, and current construction costs.</p>

                <p>It's crucial to periodically review and update your property's insurance valuation to ensure you have adequate coverage in case of a loss. Changes in construction costs, renovations, or improvements to the property should prompt a reevaluation of the insurance value. Always consult with your insurance provider for guidance on properly valuing your property for insurance coverage.     </p>                             

                `,
                banners: [
                    {
                        image: '/images/servicesslider/Asset-1.jpg',
                        title: 'Fire Safety Audit'
                    },
                    {
                        image: '/images/servicesslider/Asset-2.jpg',
                        title: 'Fire Safety Audit'
                    },
                   
                ]
            }
        ],
    },
];

export default SerivcesData;