
import React, { useRef } from "react";
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import { Grid, Navigation, Mousewheel, Pagination } from "swiper/modules";
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "lucide-react";

function Carousel({ children, slidesPerView, spaceBetween, breakpoints, rows, loop, className, pagination, arrows }) {

    const swiperRef = useRef();

    const goToNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goToPrevSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className={`relative h-fit w-full overflow-hidden ${className}`}>
            <Swiper
                slidesPerGroup={1}
                loop={loop}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                grid={{ rows: rows, fill: "row" }}
                modules={[Grid, Navigation, Mousewheel, Pagination]}
                className="mySwiper"
                breakpoints={breakpoints}
                pagination={pagination}
                ref={swiperRef}
                mousewheel={true}
                style={{
                    overflow: 'visible'
                }}
            >
                {children}
            </Swiper>
            {
                arrows && (
                    <div className="flex items-center justify-center gap-6 mt-6">
                        <ChevronLeft
                            size={32}
                            className="border p-1.5 flex items-center justify-center text-white rounded-full cursor-pointer"
                            onClick={goToPrevSlide}
                        />
                        <ChevronRight
                            size={32}
                            className="border p-1.5 flex items-center justify-center text-white rounded-full cursor-pointer"
                            onClick={goToNextSlide}
                        />
                    </div>
                )
            }
        </div>

    )
};

Carousel.defaultProps = {
    children: '',
    slidesPerView: 3,
    spaceBetween: 10,
    breakpoints: {},
    rows: 1,
    loop: true,
    className: '',
    pagination: { clickable: true },
    arrows: false
};

Carousel.propTypes = {
    children: PropTypes.string,
    slidesPerView: PropTypes.number,
    spaceBetween: PropTypes.number,
    breakpoints: PropTypes.arrayOf(),
    rows: PropTypes.number,
    loop: PropTypes.bool,
    className: PropTypes.string,
    pagination: PropTypes.bool,
    arrows: PropTypes.bool
};


export default Carousel