import Image from "next/image";
import PropTypes from "prop-types";
import Heading from "../atoms/Heading";

const CardImageBig = ({ cardimageUrl, headingText, onClick, width, height }) => {
  return (
    <div className={`relative cursor-pointer ${width} ${height}`} onClick={onClick}>
      <Image
        src={cardimageUrl}
        alt="Services"
        layout="fill"
        className="object-cover rounded-2xl brightness-50"
      />
      <div className="flex justify-between gap-4 p-6 items-end w-full h-full relative z-1">
        <Heading type="h3" color="text-white" className="font-bold" >
          {headingText}
        </Heading>
        <div className="flex-none">
          <Image src="/images/arrow-right.svg" alt="arrow" width={24} height={24} />
        </div>
      </div>
    </div>
  );
};

CardImageBig.defaultProps = {
  cardimageUrl: '',
  headingText: '',
  onClick: () => { },
  width: 'w-full',
  height: 'h-[150px] md:h-[300px]'
};

CardImageBig.propTypes = {
  cardimageUrl: PropTypes.string,
  headingText: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string
};


export default CardImageBig;